<template>
  <Navbar />

  <body class="bg-light" style="min-height: 100vh;">
    <div style="height: 75px;"></div>
    <div class="container">
      <div class="row">
        <div style="font-size: 20px;" class="col-6">
          <font-awesome-icon :icon="['fas', 'diagram-project']" /> รายการสถานที่</div>
        <div class="col-6 text-end">
          ค้นหาสถานที่ 
        <input v-model="message" placeholder=" การค้นหา"
            class="rounded-4 border" style="height: 35px" /><button type="button"
            class="btn text-white rounded-5 ms-1 px-2 py-1" style="background-color: #54ACEC">  <font-awesome-icon :icon="['fas', 'magnifying-glass']" /> ค้นหา</button></div>
      </div>
      <div class="row justify-content-center ">
        <div class="col-11">

          <div class="row mt-3">
            <div class="card border-0 grad rounded-5 mb-3 col-3 ms-3 shadow" v-for="(item, index) in items" :key="index"
              style="max-width: 17rem;height: 135px;">
              <router-link style="text-decoration: none; color: inherit;" :to="`/inside?name=${item.station}`">
              <div class="card-body">
                <div class="row">
                  <h5 class="card-title col-8" style="color:  #139CDA;">
                    <font-awesome-icon :icon="['fas', 'diagram-project']" /> {{ item.station }}</h5>
                  <div class="col-4 text-end"> <img src="../assets/rid_logo.png" alt="" width="26"></div>
                </div>
                <div>Description: สถานีสูบน้ำ</div>
                <div style="font-size: 12px;"><span>ละติจูด: 10.00000</span><span> ลองจิจูด: 103.34567</span></div>
                <div>สถานะการทำงาน Online</div>
                <div style="font-size: 12px;color: grey;" class="text-end">ID: 000000{{ index }}</div>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>


</template>

<script>
  import Navbar from "@/components/navbar_web.vue";
  export default {
    components: {

      Navbar
    },
    data() {
      return {
        selected: "ทั้งหมด",
        headers: [{
            text: "สถานี",
            value: "station"

          },
          {
            text: "วันที่-เวลา",
            value: "datetime"
          },
          {
            text: "สถานะ",
            value: "status"
          }
        ],
        items: [{
            station: "Station A",
            datetime: "123456",
            status: "ON"
          },
          {
            station: "Station B",
            datetime: "123456",
            status: "ON"
          },

          {
            station: "Station C",
            datetime: "123456",
            status: "ON"
          },
          {
            station: "Station D",
            datetime: "123456",
            status: "ON"
          },
          {
            station: "Station E",
            datetime: "123456",
            status: "ON"
          },
          {
            station: "Station F",
            datetime: "123456",
            status: "ON"
          }
        ]

      };
    },
    computed: {

    },
    methods: {

    }
  };
</script>


<style>
  .customize-table {
    --easy-table-header-font-size: 18px;
    --easy-table-body-row-font-size: 16px;
  }

  .grad {
    /* background-image: linear-gradient(to right, #5487edda , #54aded67); */
    /* background: linear-gradient(to right, #139CDA, #139bdaa2); */
  }
</style>