<template>
  <Navbar_sub />

  <body class="bg-white">
    <!-- style="background-color: #f3f8fc;" -->
    <div style="height: 60px;"></div>

    <div class="row justify-content-center">
      <div class="col-10 ">

        <h4 class="text-start mt-4 px-0 py-0" style="">
          <font-awesome-icon :icon="['fas', 'diagram-project']" style="font-size: 18px;color: grey;" /> สถานี:
          {{ $route.query.name }}</h4>

        <!-- <div class="text-end" style="font-size: 12px;color: grey;">Last Update: 12:12:12 10/08/24</div> -->
        <div class="mt-1">
          <font-awesome-icon :icon="['fas', 'expand']" /> Overview</div>
        <div class="row mt-3">
          <div class="col-6 ">
            <div class="col-12 mt-2" style="font-size: 10px;">
              <div class="row justify-content-center px-4 py-2">
                <div class="col-6 px-4" style="max-width: 270px;">
                  <div class="nameText text-center">
                    <font-awesome-icon :icon="['fas', 'clock']" style="color: #0099BD;" /> Runtime (HH:MM)
                  </div>
                  <div class="valueText text-center" style="color:  #0098DA;">
                    00:00
                  </div>
                </div>
                <div class="col-6  px-4 ms-2" style="max-width: 270px;">
                  <div class="nameText text-center">
                    <font-awesome-icon :icon="['fas', 'clock']" style="color: #0099BD;" /> Runtime (HH:MM)
                  </div>
                  <div class="valueText text-center " style="color:  #0098DA;">
                    00:00
                  </div>
                </div>
              </div>

              <div class="row px-2 py-2 justify-content-center" style="font-size: 12px;">
                <div class="col-2 bg-light px-3 py-3 gradientStyle rounded-4 " style="min-width: 140px;height: 120px;">
                  <div class="nameText">
                    <font-awesome-icon :icon="['fas', 'spinner']" style="color: #0099BD;" /> Flow Rate
                  </div>
                  <div class="valueText text-center" style="color: #0098DA;">
                    00.00
                  </div>
                  <div class="nameText text-center" style="color: grey;">
                    m3/hr
                  </div>
                </div>

                <div class="col-2     px-3 py-3 gradientStyle rounded-4  bg-light  ms-2 " style="min-width: 140px;">
                  <div class="nameText">
                    <font-awesome-icon :icon="['fas', 'gauge']" style="color: #0099BD;" /> Speed
                  </div>
                  <div class="valueText text-center" style="color: #0098DA;">
                    00.00
                  </div>
                  <div class="nameText text-center" style="color: grey;">
                    rpm
                  </div>
                </div>
                <div class="col-2     px-3 py-3 gradientStyle rounded-4  bg-light  ms-2 " style="min-width: 140px;">
                  <div class="nameText">
                    <font-awesome-icon :icon="['fas', 'wave-square']" style="color: #0099BD;" /> Vibration
                  </div>
                  <div class="valueText text-center" style="color: #0098DA;">
                    00.00
                  </div>
                  <div class="nameText text-center" style="color: grey;">
                    mm/sec
                  </div>
                </div>
                <div class="col-2     px-3 py-3 gradientStyle rounded-4  bg-light  ms-2 " style="min-width: 140px;">
                  <div class="nameText">
                    <font-awesome-icon :icon="['fas', 'download']" style="color: #0099BD;" /> Spare
                  </div>
                  <div class="valueText text-center" style="color: #0098DA;">
                    00.00
                  </div>
                  <div class="nameText text-center" style="color: grey;">
                    - -
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-6 ">
            <div>
              <div>
                <font-awesome-icon :icon="['fas', 'location-dot']" style="color: #0099BD;" /> สถานที่</div>
              <div class=" rounded-4 bg-light" style="height: 200px;">
                <l-map ref="map" :center="markerLatLng" v-model:zoom="zoom" style="height: 200px;" class="rounded-4">
                  <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
                    name="OpenStreetMap">
                  </l-tile-layer>
                        <l-marker :lat-lng=" [14.348360, 100.576271]" >
        <l-popup>
          <br><strong>Location Name: </strong><br><strong>Date: </strong><br><button style="margin:5% 0% 0% 35%;">Add</button>
        </l-popup>
      </l-marker>
                </l-map>

              </div>
            </div>
            <!-- <div class="col-12">
              <div class="row  rounded-5 ">
                <div id="" class="col-3 mx-1 bg-white rounded-5 border" style="min-width: 190px;">
                  <apexchart height="110" :options="chartOptions" :series="series"></apexchart>
                  <div class="text-center nameText"><font-awesome-icon :icon="['fas', 'bolt']" style="color: #3498db;"/> Sysytem Voltage</div>
                  <div class="text-center textvalue-power" style="color:  #64e4a2;">00.00 <span style="font-size: 14px;color: black;">Volt (V)</span></div>
                  <div style="height: 10px;"></div>
                </div>

                <div id="" class="col-3 mx-1 bg-white rounded-5 border" style="min-width: 190px;">
                  <apexchart height="110" :options="chartOptions" :series="series"></apexchart>
                  <div class="text-center nameText">  <font-awesome-icon :icon="['fas', 'wave-square']" style="color: #3498db;"/> Sysytem Current</div>
                  <div class="text-center textvalue-power" style="color: #64e4a2;">00.00 <span style="font-size: 14px;color: black;">Amp (A)</span></div>
                  <div style="height: 10px;"></div>
                </div>

                <div id="" class="col-3 mx-1 bg-white rounded-5 border" style="min-width: 190px;">
                  <apexchart height="110" :options="chartOptions" :series="series"></apexchart>
                  <div class="text-center nameText"><font-awesome-icon :icon="['fas', 'gauge-high']" style="color: #3498db;"/> Active Power</div>
                  <div class="text-center textvalue-power" style="color: #64e4a2;">00.00 <span style="font-size: 14px;color: black;">Watt (kW)</span></div>
                  <div style="height: 10px;"></div>
                </div>

              </div>
            </div> -->
          </div>
        </div>

      </div>
    </div>
    <br>
    <div class="row justify-content-center pt-2" style="background-color: #f3f8fc;">
      <div class="col-10">
        <font-awesome-icon :icon="['fas', 'temperature-low']" /> Realtime Temperature</div>
    </div>
    <div class="row justify-content-center" style="background-color: #f3f8fc;">
      <div class="col-10">
        <div class="row">
          <div class="col-2 " style="" v-for="item in items" :key="item">
            <div class="row px-2 py-2">
              <div class="col-12 border-0  rounded-5 bg-white" style="">
                <div class="mt-3">
                  <!-- <GaugeChart /> -->
                  <apexchart class="px-0 py-0" type="radialBar" height="150" :options="chartOptionsR" :series="seriesR">
                  </apexchart>
                </div>
                <div class="text-center px-0 py-0 my-0" style="color: gray;">
                  <font-awesome-icon :icon="['fas', 'temperature-three-quarters']" /> {{ item.name }}
                </div>
                <div class="text-center  textTemp" style="color: #5D90CB;">
                  0.00 <span style="font-size: 20px;"> °C</span>
                </div>
                <!-- <div class="text-center" style="font-size: 14x;">Unit</div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-2">
          <div class="mt-2">
            <font-awesome-icon :icon="['fas', 'temperature-low']" /> Pump And Power</div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="row">
              <div class="col-6  px-2 py-2">
                <div class="rounded-5 bg-white shadow" style="height: 420px;">
                  <div class="col-12">
                    <div class="h4 pt-3 col-12 px-4" style="font-weight: bold;">
                      <font-awesome-icon :icon="['fas', 'rotate']" style="color: grey;font-size: 18px;" /> &nbsp;Pump
                      type: PUMPRUN0001</div>
                    <div class="row">
                      <div class="col-3 text-center">
                        <div class="col-12 text-center" style="height: 70px;">
                          <div class="nameText">Name Value1</div>
                          <div class="valueText">0.00 <span style="font-size: 14px;">°C</span></div>
                        </div>
                        <div class="col-12 text-center" style="height: 70px;">
                          <div class="nameText">Name Value2</div>
                          <div class="valueText">0.00 <span style="font-size: 14px;">°C</span></div>
                        </div>
                        <div class="col-12 text-center" style="height: 70px;">
                          <div class="nameText">Name Value3</div>
                          <div class="valueText">0.00 <span style="font-size: 14px;">°C</span></div>
                        </div>
                        <div class="col-12 text-center" style="height: 70px;">
                          <div class="nameText">Name Value4</div>
                          <div class="valueText">0.00 <span style="font-size: 14px;">°C</span></div>
                        </div>
                        <div class="col-12 text-center" style="height: 70px;">
                          <div class="nameText">Name Value5</div>
                          <div class="valueText">0.00 <span style="font-size: 14px;">°C</span></div>
                        </div>
                      </div>

                      <div class="col-6 text-center">
                        <div class=" mt-3" style="height: 500px;">
                          <h1 class="mt-4">IMAGE</h1>
                          <!-- <div>Here is URL image</div> -->
                        </div>
                      </div>

                      <div class="col-3 text-center">
                        <div class="col-12 text-center" style="height: 70px;">
                          <div class="nameText">Name Value1</div>
                          <div class="valueText">0.00 <span style="font-size: 14px;">°C</span></div>
                        </div>
                        <div class="col-12 text-center" style="height: 70px;">
                          <div class="nameText">Name Value2</div>
                          <div class="valueText">0.00 <span style="font-size: 14px;">°C</span></div>
                        </div>
                        <div class="col-12 text-center" style="height: 70px;">
                          <div class="nameText">Name Value3</div>
                          <div class="valueText">0.00 <span style="font-size: 14px;">°C</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6  px-2 py-2">
                <div class="px-5 rounded-5 bg-white shadow" style="height: 420px;font-size: 12.5px;">
                  <div class="h4 col-12 pt-3 " style="font-weight: bold;">
                    <font-awesome-icon :icon="['fas', 'bolt']" style="color: grey;font-size: 18px;" />&nbsp;POWER
                    CONSUMPTION DATA</div>
                  <div class="col-12 mt-3">
                    <div class="row">
                      <div class="col-3"></div>
                      <div class="col-3 text-center">Phase1</div>
                      <div class="col-3 text-center">Phase2</div>
                      <div class="col-3 text-center">Phase3</div>
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="row">
                      <div class="col-3">
                        <font-awesome-icon :icon="['fas', 'bolt']" /> Voltage</div>
                      <div class="col-3 text-center">Voltage Phase1 [Unit]</div>
                      <div class="col-3 text-center">Voltage Phase2 [Unit]</div>
                      <div class="col-3 text-center">Voltage Phase3 [Unit]</div>
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="row">
                      <div class="col-3">
                        <font-awesome-icon :icon="['fas', 'wave-square']" /> Current</div>
                      <div class="col-3 text-center">Voltage Phase1 [Unit]</div>
                      <div class="col-3 text-center">Voltage Phase2 [Unit]</div>
                      <div class="col-3 text-center">Voltage Phase3 [Unit]</div>
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="row">
                      <div class="col-3">
                        <font-awesome-icon :icon="['fas', 'gauge-high']" /> Active Power</div>
                      <div class="col-3 text-center">Voltage Phase1 [Unit]</div>
                      <div class="col-3 text-center">Voltage Phase2 [Unit]</div>
                      <div class="col-3 text-center">Voltage Phase3 [Unit]</div>
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="row">
                      <div class="col-3">
                        <font-awesome-icon :icon="['fas', 'gauge-high']" /> Total Active Power</div>
                      <div class="col-3 text-center">Voltage Phase1 [Unit]</div>
                      <div class="col-3 text-center">Voltage Phase2 [Unit]</div>
                      <div class="col-3 text-center">Voltage Phase3 [Unit]</div>
                    </div>
                  </div>
                  <!-- <hr> -->



                  <div class="col-12 mt-2">
                    <div class="row">
                      <div class="col-3">
                        <font-awesome-icon :icon="['fas', 'bolt']" /> Voltage Unbalance</div>
                      <div class="col-3 text-center">Voltage [%]</div>
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="row">
                      <div class="col-3">
                        <font-awesome-icon :icon="['fas', 'wave-square']" /> Current Unbalance</div>
                      <div class="col-3 text-center">Voltage [%]</div>
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="row">
                      <div class="col-3">
                        <font-awesome-icon :icon="['fas', 'scale-balanced']" /> Power Factor</div>
                      <div class="col-3 text-center">Voltage [%]</div>
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="row">
                      <div class="col-3">
                        <font-awesome-icon :icon="['fas', 'repeat']" /> ขนาดเครื่องสูบน้ำ</div>
                      <div class="col-3 text-center">Voltage [%]</div>
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="row">
                      <div class="col-3">
                        <font-awesome-icon :icon="['fas', 'gauge-high']" /> พิกัดกระแส</div>
                      <div class="col-3 text-center">Voltage [%]</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="height: 200px;"></div>
  </body>
</template>
<script>
  import "leaflet/dist/leaflet.css";
  import {
    LMap,
    LTileLayer,
    LMarker,
    // LIcon,
    LPopup
  } from "@vue-leaflet/vue-leaflet";
  // import GaugeChart from '@/components/GaugeChart.vue';
  import Navbar_sub from "@/components/navbar_sub_web.vue";
  export default {
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LPopup,
      // GaugeChart,
      Navbar_sub
    },
    data() {
      return {
        zoom: 8,
        markerLatLng: [14.348360, 100.576271],

        items: [{
          name: 'Temperature 0'
        }, {
          name: 'Temperature 1'
        }, {
          name: 'Temperature 2'
        }, {
          name: 'Temperature 3'
        }, {
          name: 'Temperature 4'
        }, {
          name: 'Temperature 5'
        }],
        series: [{
          name: 'series1',
          data: [20, 20, 21, 20, 20, 22, 20, 22]
        }],
        chartOptions: {
          colors: ['#64e4a2'],
          grid: {
            show: false,
          },
          chart: {
            toolbar: {
              show: false,
            },
            // height: 350,
            type: 'area'
          },
          dataLabels: {
            enabled: false,
            show: false
          },
          stroke: {
            curve: 'smooth',
            width: 1.5
          },
          xaxis: {
            labels: {
              show: false,
            }
            // show:false
            // type: 'datetime',
            // categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z",
            //   "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z",
            //   "2018-09-19T06:30:00.000Z"
            // ]
          },
          yaxis: {
            labels: {
              show: false,
            }
          },
          tooltip: {
            show: false,
            x: {
              format: 'dd/MM/yy HH:mm'
            },
          },
        },

        seriesR: [45],
        chartOptionsR: {
          chart: {
            // height: 350,
            type: 'radialBar',
            toolbar: {
              show: true
            }
          },
          plotOptions: {

            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: '70%',
                //   background: '#fff',
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: 'front',
                dropShadow: {
                  enabled: true,
                  top: 1,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }
              },
              track: {
                background: '#fff',
                strokeWidth: '6%',
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.2
                }
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: 5,
                  show: true,
                  color: '#888',
                  fontSize: '12px'
                },
                value: {
                  formatter: function () {
                    return "";
                  },
                  color: '#11',
                  fontSize: '36px',
                  show: true,
                }
              }
            }
          },
          colors: ['#1D9DDA'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 1,
              gradientToColors: ['#64e4a2'],
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: 'round'
          },
          labels: ['Gauge'],
        },

      }
    },
    computed: {},
    methods: {}
  };
</script>
<style>
  .gradientStyle {
    /* background: linear-gradient(to right, #6CBEB6,#6CBEB6); */
    /* example gradient */
  }

  .nameText {
    font-size: 14px;
  }

  .color-text-name {
    color: gray;
  }

  .valueText {
    font-size: 30px;
  }

  .textvalue-power {
    font-size: 30px;
  }

  .textTemp {
    font-size: 28px;
  }
</style>

<!-- <template>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script setup>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {GaugeChart  } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { ref, provide } from 'vue';

use([
  CanvasRenderer,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

provide(THEME_KEY, 'dark');

const option = ref({
  series: [
    {
      type: 'gauge',
      progress: {
        show: true,
        width: 18
      },
      axisLine: {
        lineStyle: {
          width: 18
        }
      },
      axisTick: {
        show: false
      },
      splitLine: {
        length: 15,
        lineStyle: {
          width: 2,
          color: '#999'
        }
      },
      axisLabel: {
        distance: 25,
        color: '#999',
        fontSize: 20
      },
      anchor: {
        show: true,
        showAbove: true,
        size: 25,
        itemStyle: {
          borderWidth: 10
        }
      },
      title: {
        show: false
      },
      detail: {
        valueAnimation: true,
        fontSize: 80,
        offsetCenter: [0, '70%']
      },
      data: [
        {
          value: 70
        }
      ]
    }
  ]
});
</script>
<style scoped>
.chart {
  height: 100vh;
}
</style> -->