<template>

    <div>
        <div class="row">
            <div class="col-1 text-center" style="min-width: 100px;">
                <div class="col" style="font-weight: 700;">
                    เลือกช่วงเวลา
                </div>
            </div>
            <div class="col-1" style="min-width: 100px;">
                <div class="col">
                    ตั้งแต่วันที่
                </div>
                <input class="col-12" type="date" name="begin" placeholder="dd-mm-yyyy" value="" min="1997-01-01"
                    max="2030-12-31" style="border: none; outline: none; text-decoration: none;">

            </div>
            <div class="col-1" style="min-width: 100px;">
                <div class="col">
                    ถึงวันที่
                </div>
                <input class="col-12" type="date" name="begin" placeholder="dd-mm-yyyy" value="" min="1997-01-01"
                    max="2030-12-31" style="border: none; outline: none; text-decoration: none;">
            </div>
            <div class="col-1" style="min-width: 130px; color: rgb(255, 255, 255);">
                <button class="btn btn-info btn-rounded rounded-5" style="color: rgb(255, 255, 255);">
                    <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
                    ค้นหา
                </button>
            </div>
        </div>

        <div class="row mt-2 justify-content-center bg-light"  style="font-size: 12px;">
            <div class="col-xl-4 col-lg-4 col-md-11 col-sm-11 col-11 border-end" >
                <div class="row">
                    <h5>ค่าทางไฟฟ้า</h5>
                    <div class="col-6">
                        <div> <input type="checkbox" id="jack" value="Jack" v-model="checkedChart">
                            <label for="jack">&nbsp;Volt L1-2</label></div>

                        <div><input type="checkbox" id="john" value="John" v-model="checkedChart">
                            <label for="john">&nbsp;Volt L2-3</label></div>

                        <div>
                            <input type="checkbox" id="mike" value="Mike" v-model="checkedChart">
                            <label for="mike">&nbsp;Volt L3-1</label>
                        </div>

                        <div><input type="checkbox" id="jack" value="Jack" v-model="checkedChart">
                            <label for="jack">&nbsp;Volt Unbalance</label></div>
                    </div>
                    <div class="col-6">
                        <div> <input type="checkbox" id="jack" value="Jack" v-model="checkedChart">
                            <label for="jack">&nbsp;Current 1</label></div>

                        <div><input type="checkbox" id="john" value="John" v-model="checkedChart">
                            <label for="john">&nbsp;Current 2</label></div>

                        <div>
                            <input type="checkbox" id="mike" value="Mike" v-model="checkedChart">
                            <label for="mike">&nbsp;Current 3</label>
                        </div>

                        <div><input type="checkbox" id="jack" value="Jack" v-model="checkedChart">
                            <label for="jack">&nbsp;Current Unbalance</label></div>
                    </div>
                    <div class="col-4">
                        <div> <input type="checkbox" id="jack" value="Jack" v-model="checkedChart">
                            <label for="jack">&nbsp;ActivePower</label></div>  
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-11 col-sm-11 col-11 border-end"  >
                <div class="row">
                    <h5>ค่าอนาล็อก</h5>
                    <div class="col-12">
                        <div> <input type="checkbox" id="jack" value="Jack" v-model="checkedChart">
                            <label for="jack">&nbsp;Flow</label></div>

                        <div><input type="checkbox" id="john" value="John" v-model="checkedChart">
                            <label for="john">&nbsp;Speed</label></div>

                        <div>
                            <input type="checkbox" id="mike" value="Mike" v-model="checkedChart">
                            <label for="mike">&nbsp;Vibration</label>
                        </div>

                        <div><input type="checkbox" id="jack" value="Jack" v-model="checkedChart">
                            <label for="jack">&nbsp;Spare</label></div>
                    </div>
                   
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-11 col-sm-11 col-11" >
                <div class="row">
                    <h5>ค่าอุณหภูมิ</h5>
                    <div class="col-6">
                        <div> <input type="checkbox" id="jack" value="Jack" v-model="checkedChart">
                            <label for="jack">&nbsp;U1 Phase Temp</label></div>

                        <div><input type="checkbox" id="john" value="John" v-model="checkedChart">
                            <label for="john">&nbsp;V1 Phase Temp</label></div>

                        <div>
                            <input type="checkbox" id="mike" value="Mike" v-model="checkedChart">
                            <label for="mike">&nbsp;W1 Phase Temp</label>
                        </div>

                        <div><input type="checkbox" id="jack" value="Jack" v-model="checkedChart">
                            <label for="jack">&nbsp;Bearing Front Temp</label></div>
                    </div>
                    <div class="col-6">
                        <div> <input type="checkbox" id="jack" value="Jack" v-model="checkedChart">
                            <label for="jack">&nbsp;U2 Phase Temp</label></div>

                        <div><input type="checkbox" id="john" value="John" v-model="checkedChart">
                            <label for="john">&nbsp;V2 Phase Temp</label></div>

                        <div>
                            <input type="checkbox" id="mike" value="Mike" v-model="checkedChart">
                            <label for="mike">&nbsp;W2 Phase Temp</label>
                        </div>

                        <div><input type="checkbox" id="jack" value="Jack" v-model="checkedChart">
                            <label for="jack">&nbsp;Bearing Back Temp</label></div>
                    </div>
                    <div class="col-4">
                        <div><input type="checkbox" id="jack" value="Jack" v-model="checkedChart">
                            <label for="jack">&nbsp;Thurst Bearing</label></div>
                    </div>
                </div>
            </div>
        </div>
        <apexchart height="280" type="line" :options="options" :series="series"></apexchart>
    </div>
</template>

<script>
    export default {
        components: {

        },
        data() {
            return {
                checkedChart: null,
                options: {
                    stroke: {
    show: true,
    curve: 'straight',
    lineCap: 'butt',
    colors: undefined,
    width: 2.5,
    dashArray: 0, 
},
                    chart: {
                        id: 'vuechart-example'
                    },

                    yaxis: [{
                        title: {
                            text: "Series A"
                        },
                    }, {
                        opposite: true,
                        title: {
                            text: "Series B"
                        }
                    },
                    {
                        opposite: true,
                        title: {
                            text: "Series C"
                        }
                    }
                
                ],
                },
                series: [
                    {
                        data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6,1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
                    },
                    {
                        data: [20, 29, 37, 36, 44, 45, 50, 58,20, 29, 37, 36, 44, 45, 50, 58]
                    },
                    {
                        data: [111,111,111,111,111,111,111,111,111,111,111,111,111,111,111,111]
                    }
                ]
            }
        }
    };
</script>

<style scoped>


</style>