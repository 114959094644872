import {
  createWebHistory,
  createRouter
} from "vue-router";
import map from '@/components/map_main.vue'
import login from '@/components/login_main.vue'
import log from '@/components/log_main.vue'
import list_project from '@/components/list_project.vue'
import main_inside from '@/components/main_inside/main_inside.vue'

const routes = [{
    path: "/",
    name: "map",
    component: map,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/project",
    name: "project",
    component:  list_project,
  },
  {
    path: "/log",
    name: "log",
    component: log,
  },
  {
    path: "/inside",
    name: "main_inside",
    component: main_inside,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;