<template>

    <body class=" gradientBG " style="height: 100vh;">
        <div style="height: 100px;"></div>
        <div class="container">
            <div class="row justify-content-center ">
                <div class="card col-4  rounded-5  shadow-lg border-0  px-5 py-4" style="background-color: rgb(255 255 255 / 46%);">
                    <div class="card-body " style="height:550px;">
                        <div class="text-center">  <img src="../assets/rid_logo.png" alt="" width="110"></div>
                        <p class="card-title text-center mt-2" style="font-size: 22px;">เข้าสู่ระบบ</p>
                        <div class="text-center" style="font-size: 20px;">กรุณากรอกข้อมูลเพื่อเข้าสู่ระบบ</div>
                        <form>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label"><font-awesome-icon :icon="['fas', 'envelope']"  style="color: gray;"/> Email address</label>
                                <input type="email" class="form-control rounded-3" id="exampleInputEmail1"
                                    aria-describedby="emailHelp" style="height: 38px;">
                                <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.
                                </div> -->
                            </div>
                            <div class="mb-4">
                                <label for="exampleInputPassword1" class="form-label "><font-awesome-icon :icon="['fas', 'lock']" style="color: gray;"/> Password</label>
                                <input type="password" class="form-control rounded-3" id="exampleInputPassword1" style="height: 38px;">
                            </div>
                            
                        </form>
                        <div class="text-center mt-4"><button class="btn btn-primary txte-center rounded-5" style="width: 300px;font-size: 20px;">เข้าสู่ระบบ</button></div>
                    </div>
                </div>
            </div>
        </div>
    </body>
</template>

<script>
    export default {
        name: 'App',
        components: {},
        return: {
            data: {
                test: "1234"
            }
        }
    }
</script>

<style>
.gradientBG {
  background: linear-gradient(to right, #7eb7f8bb, #384dc5c0); /* example gradient */
}
</style>