<template>
<router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap');
@font-face {
    font-family: Maehongson;
    src: url(../src/assets/front/Maehongson.ttf);
}

div{
  font-family:  Maehongson;
}
h1{
  font-family:  Maehongson;
}

.allerta-stencil-regular {
  font-family: "Allerta Stencil", sans-serif;
  font-weight: 400;
  font-style: normal;
}


#app {
  font-family:  Maehongson;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
</style>
