<!-- <template>
  <Navbar />
  <body>
    <GoogleMap api-key="AIzaSyD_zYm-FwM1I5pRHmHm0PwsntB9g-DiaRo" style="width: 100%; height: 500px" :center="center" :zoom="15" >
    <Marker :options="{ position: center }" />
  </GoogleMap>
  </body>
</template>

<script>
import Navbar from "@/components/navbar_web.vue";
import { defineComponent } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";

export default defineComponent({
  components: { GoogleMap, Marker ,Navbar},
  setup() {
    const center = { lat: 14.348360, lng: 100.576271};

    return { center };
  },
});
</script> -->

<template>

  <Navbar style="z-index: 3" />

  <body>
    <div style="height:100vh; width:100vw">
      <!-- <div   style="position: absolute; left: 150px; top: 100px; z-index: 1;">
      <div class="text-end pe-5" style="padding-top: 250px;">   <button type="button" class="btn btn-info   rounded-circle btn-lg" style="background-color: #55acee;"><font-awesome-icon :icon="['fas', 'up-right-and-down-left-from-center']" style="font-size: 14px;color: #fff;"/></button></div>
    </div> -->
      <div v-if="status_box == true" style="position: absolute; left: 35px; top: 180px; z-index: 1;">
        <div class="card shadow-sm bg-light" style="width: 18rem;">
          <div class="card-body ">
            <h5 class="card-title" style="font-weight: 700;color: #344767;">สถานะการทำงาน</h5>
            <h6 class="card-subtitle mb-2 text-muted">สัญลักษณ์แสดงสถานะการทำงาน</h6>
            <ul class="list-group list-group-flush ">
              <li class="list-group-item bg-light">
                <div class="row">
                  <div class="col-7">สถานะไม่ทำงาน</div>
                  <div class="col-5 text-end" style="">&#9899;</div>
                </div>
              </li>
              <li class="list-group-item bg-light">
                <div class="row">
                  <div class="col-7">สถานะทำงานปกติ</div>
                  <div class="col-5 text-end" style="">&#9899;</div>
                </div>
              </li>
              <li class="list-group-item bg-light">
                <div class="row">
                  <div class="col-7">สถานะผิดปกติ</div>
                  <div class="col-5 text-end" style="">&#9899;</div>
                </div>
              </li>
            </ul>
            <a href="#" class="card-link">รายงาน</a>
          </div>
        </div>
      </div>
      <div v-if="status_box == true" style="position: absolute; left: 12px; top: 160px; z-index: 1;">
        <button type="button" class="btn btn-info rounded-circle btn-lg" style="background-color: #55acee;"
          @click="status_box=false">
          <font-awesome-icon :icon="['fas', 'down-left-and-up-right-to-center']" style="font-size: 14px;color: #fff;" />
        </button>
      </div>

      <div v-else-if="status_box == false" style="position: absolute; right: 10px; bottom: 500px; z-index: 1;">
        <div class="text-end " style="padding-top: 250px;">
          <button type="button" class="btn btn-info rounded-circle btn-lg" style="background-color: #55acee;"
            @click="status_box=true">
            <font-awesome-icon :icon="['fas', 'up-right-and-down-left-from-center']"
              style="font-size: 14px;color: #fff;" />
          </button>
        </div>
      </div>

      <!-- <div style="position: absolute; right: 10px; bottom: 440px; z-index: 1;">
        <div class="text-end " style="padding-top: 250px;">
          <button type="button" class="btn btn-info rounded-circle btn-lg" style="background-color: #55acee;"  @click="status_box=true">
            <font-awesome-icon :icon="['fas', 'up-right-and-down-left-from-center']"
              style="font-size: 14px;color: #fff;" />
          </button>
        </div>
      </div> -->

      <div style="position: absolute; right: 4cqw; top: 100px; z-index: 1;">
        <div style="font-size: 1.5cqw;color: #000000;text-shadow: 2px 1px #34476760;">โครงการสถานีสูบน้ำด้วยไฟฟ้า
          สถานีสูบน้ำ P.5</div>
        <div class="text-end" style="font-size: 18px;color: #555555;">โครงการสถานีสูบน้ำด้วยไฟฟ้า</div>
      </div>
      <div style="position: absolute; right: 4cqw; top: 18.5cqh; z-index: 1;">
        <select v-model="selected" style="width: 280px;height: 25px;" class="rounded  border-dark shadow-lg">
          <option disabled value="">เลือกสถานะการทำงาน</option>
          <option>สถานะไม่ทำงาน</option>
          <option>สถานะทำงานปกติ</option>
          <option>สถานะผิดปกติ</option>
        </select>
      </div>


      <l-map ref="map" v-model:zoom="zoom" :center="markerLatLng" style="position: absolute; z-index: 0;">
        <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base" name="OpenStreetMap">
        </l-tile-layer>
        <!-- <l-marker :lat-lng=" [14.348360, 100.576271]" >
        <l-popup>
          <br><strong>Location Name: </strong><br><strong>Date: </strong><br><button style="margin:5% 0% 0% 35%;" @click="addStops(marker)">Add</button>
        </l-popup>
      </l-marker> -->

        <l-marker v-for="(item, index) in items" :key="index" :lat-lng="getLatLng(index)" @click="test">
          <l-icon class-name="someExtraClass">

            <img class="myDIV" type="button" @click="console.log(1234)" src="../assets/rid_logo.png">
            <div class="" style="margin-top: -75px;margin-left: -6px;">🟢</div>

            <!-- <button type="button" class="btn btn-primary" v-on:click="test()">Primary{{ s }}</button> -->
            <div class="hide border rounded-3" style=" font-size: 20px;">
              <div class="text-center" style="font-size: 14px;">ชื่อสถานี</div>
              <div class="text-center" style="font-size: 16px;">{{ item.name }}</div>
            </div>

          </l-icon>
          <l-popup closeButton="false">
            <div class="col">
              <router-link style="text-decoration: none; color: inherit;" :to="`/inside?name=${item.name}`">
                <div type="button" class="rounded-5  p-1 shadow" style="background-color: #eeeeeec9;"> <button
                    type="button" class="btn  p-1 rounded-5 btn-lg " style="background-color: #1a94f869;">
                    <font-awesome-icon :icon="['fas', 'house']" /></button><span
                    style="font-size: 14px;font-weight: 700;"> หน้าหลัก</span>
                </div>
              </router-link>
              <div type="button" class="rounded-5  p-1 mt-1 shadow" data-bs-toggle="modal"
                data-bs-target="#staticBackdrop" style="background-color: #eeeeeec9;"> <button type="button"
                  class="btn  p-1 rounded-5 btn-lg" style="background-color: #1a94f869;">
                  <font-awesome-icon :icon="['fas', 'chart-simple']" /></button><span
                  style="font-size: 14px;font-weight: 700;"> กราฟ</span>
              </div>

            </div>
          </l-popup>
        </l-marker>
      </l-map>
    </div>
  </body>

  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered  modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            <font-awesome-icon :icon="['fas', 'chart-simple']" /> กราฟแสดงผล</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <Chart_Consumption />
          <div>
          </div>
          <Log_Consumption />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import "leaflet/dist/leaflet.css";
  import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  } from "@vue-leaflet/vue-leaflet";
  import Navbar from "@/components/navbar_web.vue";
  import Chart_Consumption from "@/components/charts/consumpsion_chart.vue";
  import Log_Consumption from "@/components/charts/consumption_log.vue";
  export default {
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LIcon,
      Navbar,
      Chart_Consumption,
      Log_Consumption,
      LPopup
    },
    data() {
      return {
        s: 'ss',
        selected: '',
        status_box: true,
        items: [{
          latLng: [14.348360, 100.576271],
          icon: "../assets/logo.png",
          name: "สถานีสูบน้ำโซน 2 ตอน 1"
        }, {
          latLng: [14.148360, 100.576271],
          icon: "../assets/logo.png",
          name: "สถานีสูบน้ำโซน 2 ตอน 2 สถานีสูบน้ำ"
        }, {
          latLng: [14.438360, 100.176271],
          icon: "../assets/logo.png",
          name: "สถานีสูบน้ำโซน 2 ตอน 3 สถานีสูบน้ำ"
        },

        {
          latLng: [14.638360, 100.776271],
          icon: "../assets/logo.png",
          name: "สถานีสูบน้ำโซน 2 ตอน 3 สถานีสูบน้ำ"
        },
      
      ],
        zoom: 10,
        markerLatLng: [14.348360, 100.576271],
        iconSize: 64,
        staticAnchor: [16, 1],

      };
    },
    computed: {
      dynamicSize() {
        return [this.iconSize, this.iconSize * 1.15];
      },
      dynamicAnchor() {
        return [this.iconSize / 2, this.iconSize * 1.15];
      }
    },
    methods: {
      test() {
        this.s = "sssjghjhsakdgh"
        console.log(1234)
      },
      getLatLng(i) {
        return this.items[i]["latLng"];
      }
    },
    created() {
      // this.test()
    }
  };
</script>

<style>
  @import '../components/style/hover.css';
  @import '../components/style/round_bt.css';

  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    padding-left: 80px;
    margin-bottom: -20px;
    background-color: #00000000;
    box-shadow: none !important;
  }

  .someExtraClass {
    padding: 10px;
  }

  .red {
    background-color: red;
    position: absolute;
  }

  .box {
    width: 100px;
    height: 100px;
    color: white;
    text-align: center;
  }

  .modal-xl {
    min-width: 90dvw;
  }
</style>