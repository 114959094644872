<template>
  <nav class="navbar navbar-expand-lg  shadow-sm  bg-white fixed-top">
    <div class="container-fluid container">
      <img src="../assets/rid_logo.png" alt="" width="40">
      <a class="navbar-brand ms-2" href="#" style="font-size: 20px; font-weight: 700; color: #344767;">
    <div style="margin: 0;padding: 0;">กรมชลประทาน</div>
    <!-- <div class="border" style="font-size: 16px; color: black; font-weight: 200; margin: 0;padding: 0;">aaaa</div> -->
</a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
            <li class="nav-item  rounded-5 shadow-sm" style="background-color:  #54ACED;">
              <!-- <router-link style="text-decoration: none; color: white;" to="/"> -->
                <a class="nav-link active mx-2" aria-current="page" href="#" style="font-weight: 500;color: white;">
                  <font-awesome-icon :icon="['fas', 'circle-notch']" /> Realtime</a>
              <!-- </router-link> -->
            </li>
            <!-- <li class="nav-item">
              <router-link style="text-decoration: none; color: inherit;" to="/">
                <a class="nav-link active mx-2" aria-current="page" href="#" style="font-weight: 700;color: #344767;">
                  <font-awesome-icon :icon="['fas', 'circle-notch']" /> Realtime</a>
              </router-link>
            </li> -->
            <li class="nav-item">
              <!-- <router-link style="text-decoration: none; color: inherit;" to="/"> -->
                <a class="nav-link active mx-2" aria-current="page" href="#" style="font-weight: 700;color: #344767;">
                  <font-awesome-icon :icon="['fas', 'chart-simple']" /> กราฟ</a>
              <!-- </router-link> -->
            </li>
            <li class="nav-item">
              <!-- <router-link style="text-decoration: none; color: inherit;" to="/log"> -->
                <a class="nav-link active mx-2" aria-current="page" href="#" style="font-weight: 700;color: #344767;">
                  <font-awesome-icon :icon="['fas', 'floppy-disk']" style="color: #344767;" /> รายงาน</a>
              <!-- </router-link> -->
            </li>
          </ul>
        </ul>

        <form class="d-flex bg-white">
         
          <button class="btn ms-3 rounded-5 border-0 px-2 py-1" type="submit">  <router-link style="text-decoration: none; color: white;" to="/project">กลับสู่หน้าหลัก</router-link></button>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    // Your JavaScript logic here
  };
</script>

<style scoped>
  @import '../components/style/navbar_global.css';

  /* Your CSS styling here */
  .btn {
    background-image: linear-gradient(195deg, #66BB6A 0%, #43A047 100%);
    color: white;
  }
</style>