<template>
<div class="col-12 mt-5 px-3" >
    <div style="font-size: 20px;" class="mb-2"><font-awesome-icon :icon="['fas', 'bell']" /> การแจ้งเตือน</div>
      <EasyDataTable :headers="headers" :items="items"  table-class-name="customize-table"/>
    </div>
</template>

<script>
    export default {
        components: {

        },
        data() {
      return {
        selected: "ทั้งหมด",
        headers: [
          {
            text: "สถานี",
            value: "station"

          },
          {
            text: "วันที่-เวลา",
            value: "datetime"
          },
          {
            text: "สถานะ",
            value: "status"
          }
        ],
        items: [
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },

          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
        
        ]

      };
    },
    };
</script>

<style >
.customize-table {
  --easy-table-header-font-size: 20px;
  --easy-table-body-row-font-size: 16px;
  --easy-table-body-row-height: 36px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #e0e0e0;
  /* height: 200px; */
}

</style>